import { Flex, Link as ChakraLink, Table, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { Link, useNavigate } from 'react-router-dom';
import { Address, AddressSuggestionWithAddressInstance } from 'types/shared-types';
import { toNormalCase } from 'utils';
import { generateAddressString } from 'utils/address';

type InvalidAddressesTableRowProps = AddressSuggestionWithAddressInstance & {
  handleUpdateAddress: (address: AddressSuggestionWithAddressInstance) => void;
  onSelectChange: (addressId: string, selectedIndex: number) => void;
};

export const InvalidAddressesTableRow = ({
  id,
  address,
  suggestions,
  handleUpdateAddress,
  onSelectChange,
}: InvalidAddressesTableRowProps) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/transactions/${address.transaction_id}`);
  };

  return (
    <Table.Row>
      <Table.Cell width={{ sm: '350px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <ChakraLink asChild>
            <Link to={`?transaction_id=${address.transaction_id}`}>
              {generateAddressString(address, {
                phone: () => '',
              })}
            </Link>
          </ChakraLink>
        </Flex>
      </Table.Cell>
      {suggestions.length > 0 && (
        <Table.Cell width={{ sm: '400px' }}>
          <Flex alignItems="center" justify="space-between" flexWrap="nowrap">
            <NativeSelectRoot size="sm" width={'380px'}>
              <NativeSelectField onChange={e => onSelectChange(id, Number(e.target.value))}>
                {suggestions.map((suggestion: Address, idx: number) => (
                  <option key={`${suggestion.postal_code}-${idx}`} value={idx}>
                    {`${generateAddressString(suggestion, {
                      city: toNormalCase,
                      county: toNormalCase,
                    })}`}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
            <Button
              size="xs"
              w={'140px'}
              variant={'outline'}
              colorPalette="blue"
              onClick={() => {
                handleUpdateAddress({ id, address, suggestions });
              }}
            >
              Update
            </Button>
          </Flex>
        </Table.Cell>
      )}
      {suggestions?.length < 1 && (
        <Table.Cell width={{ sm: '180px' }} maxW="20rem">
          <Flex alignItems="center" justify="space-between" flexWrap="nowrap">
            <Text>No Suggestions Found</Text>
            <Button w={'140px'} variant={'outline'} color={'primary'} onClick={handleRowClick}>
              View transaction
            </Button>
          </Flex>
        </Table.Cell>
      )}
    </Table.Row>
  );
};
