import { Skeleton, Table } from '@chakra-ui/react';

type TableRowSkeletonProps = {
  length?: number;
  columns?: string[];
  showEmptyColumn?: boolean;
};
export const TableRowSkeleton = ({ length = 10, columns = [], showEmptyColumn = false }: TableRowSkeletonProps) => {
  return (
    <>
      {Array.from({ length }, (_, index) => (
        <Table.Row height="55px" key={index}>
          {columns.map(col => (
            <Table.Cell key={col}>
              <Skeleton width="full" h="20px" />
            </Table.Cell>
          ))}
          {showEmptyColumn && (
            <Table.Cell>
              <Skeleton minWidth="105px" h="20px" />
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </>
  );
};
