import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { ADDRESS_STATE_KEY, bulkSuggestionAddresses, getAddresses } from 'apis/addressses';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import { AddressStatus } from 'schema/types-schema.d';
import { AddressInstance, AddressSuggestion, AddressSuggestionWithAddressInstance } from 'types/shared-types';

import { InvalidAddressTable } from './components/table';

const BulkCleanupAddresses = () => {
  const addressTableFilters = useTableFilters({
    status: AddressStatus.INVALID,
  });

  const {
    page,
    size,
    status,
    address_not_empty,
    has_city,
    has_country,
    has_county,
    has_postal_code,
    has_state,
    setFilters,
  } = addressTableFilters;

  const { orgId } = useOrg();

  const { isPending, data } = useQuery({
    queryKey: [
      ADDRESS_STATE_KEY,
      orgId,
      page,
      size,
      status,
      address_not_empty,
      has_city,
      has_country,
      has_county,
      has_postal_code,
      has_state,
    ],
    queryFn: async () => {
      const { data } = await getAddresses(orgId, {
        page,
        size,
        status,
        address_not_empty,
        has_city,
        has_country,
        has_county,
        has_postal_code,
        has_state,
      });
      return data;
    },
  });

  const invalidAddresses = data?.items || [];
  const totalPages = data?.pages ?? 0;
  const isPaginationEnable = invalidAddresses.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;

  const { isLoading: isLoadingSuggestions, data: suggestedAddresses } = useQuery<
    AddressSuggestion[],
    null,
    AddressSuggestionWithAddressInstance[]
  >({
    queryKey: [ADDRESS_STATE_KEY, orgId, invalidAddresses.map(({ id }) => id)],
    queryFn: async () => {
      const { data } = await bulkSuggestionAddresses(orgId, invalidAddresses);
      return data;
    },
    select: data => {
      const res = data.map(item => {
        const address = invalidAddresses.find(({ id }) => id === item.id);
        return {
          id: item.id,
          suggestions: item.suggestions,
          address: {
            ...address,
            ...item.address,
          } as AddressInstance,
        };
      });
      return res;
    },
    enabled: invalidAddresses.length > 0 && !isPending,
  });

  return (
    <Stack height={'100%'}>
      <InvalidAddressTable
        addresses={suggestedAddresses || []}
        tableFilters={addressTableFilters}
        isLoading={isPending || isLoadingSuggestions}
        size={size}
      />
      {isPaginationEnable && (
        <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
      )}
    </Stack>
  );
};

export default BulkCleanupAddresses;
