import { CheckboxGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';
import { UseTableFilters } from 'types/shared-types';

type AddressFilterProps = {
  onClose: () => void;
  filters: UseTableFiltersType;
};
export const AddressFilter = ({ onClose, filters }: AddressFilterProps) => {
  const { has_city, has_country, has_county, has_postal_code, has_state, address_not_empty, setFilters, resetFilters } =
    filters;

  const [selectedPostalCode, setSelectedPostalCode] = useState<boolean>(
    has_postal_code !== undefined && !has_postal_code
  );
  const [selectedState, setSelectedState] = useState<boolean>(has_state !== undefined && !has_state);
  const [selectedCounty, setSelectedCounty] = useState<boolean>(has_county !== undefined && !has_county);
  const [selectedCity, setSelectedCity] = useState<boolean>(has_city !== undefined && !has_city);
  const [selectedCountry, setSelectedCountry] = useState<boolean>(has_country !== undefined && !has_country);
  const [selectedAddressNotEmpty, setSelectedAddressNotEmpty] = useState<boolean>(
    address_not_empty !== undefined && address_not_empty
  );

  const handleSave = () => {
    const filterParams: UseTableFilters = {};

    if (selectedAddressNotEmpty) {
      filterParams.address_not_empty = 'true';

      // reset the other filters if address_not_empty is present
      filterParams.has_postal_code = undefined;
      filterParams.has_city = undefined;
      filterParams.has_county = undefined;
      filterParams.has_country = undefined;
      filterParams.has_state = undefined;
    } else {
      if (selectedPostalCode) {
        filterParams.has_postal_code = 'false';
      }
      if (selectedCity) {
        filterParams.has_city = 'false';
      }
      if (selectedCounty) {
        filterParams.has_county = 'false';
      }
      if (selectedCountry) {
        filterParams.has_country = 'false';
      }
      if (selectedState) {
        filterParams.has_state = 'false';
      }

      // reset the address_not_empty filter if any of the other filters are present
      filterParams.address_not_empty = undefined;
    }
    setFilters(filterParams);
    onClose();
  };

  const handleClear = () => {
    setSelectedState(false);
    setSelectedCity(false);
    setSelectedCounty(false);
    setSelectedCountry(false);
    setSelectedPostalCode(false);
    setSelectedAddressNotEmpty(false);

    resetFilters();
    onClose();
  };

  const hasFilter =
    selectedPostalCode || selectedState || selectedCounty || selectedCity || selectedCountry || selectedAddressNotEmpty;

  return (
    <Stack gap={'16px'}>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <Stack>
        <CheckboxGroup>
          <Checkbox
            checked={selectedPostalCode}
            disabled={selectedAddressNotEmpty}
            onCheckedChange={({ checked }) => setSelectedPostalCode(checked as boolean)}
          >
            Missing Postal Code
          </Checkbox>
          <Checkbox
            checked={selectedCity}
            disabled={selectedAddressNotEmpty}
            onCheckedChange={({ checked }) => setSelectedCity(checked as boolean)}
          >
            Missing City
          </Checkbox>
          <Checkbox
            checked={selectedCounty}
            disabled={selectedAddressNotEmpty}
            onCheckedChange={({ checked }) => setSelectedCounty(checked as boolean)}
          >
            Missing County
          </Checkbox>
          <Checkbox
            checked={selectedState}
            disabled={selectedAddressNotEmpty}
            onCheckedChange={({ checked }) => setSelectedState(checked as boolean)}
          >
            Missing State
          </Checkbox>
          <Checkbox
            checked={selectedCountry}
            disabled={selectedAddressNotEmpty}
            onCheckedChange={({ checked }) => setSelectedCountry(checked as boolean)}
          >
            Missing Country
          </Checkbox>
          <Checkbox
            checked={selectedAddressNotEmpty}
            disabled={selectedCity || selectedState || selectedCountry || selectedPostalCode}
            onCheckedChange={({ checked }) => setSelectedAddressNotEmpty(checked as boolean)}
          >
            Hide Empty Addresses
          </Checkbox>
        </CheckboxGroup>
      </Stack>

      <HStack direction="row" justifyContent={'space-around'} gap="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button variant="solid" color="primary" onClick={handleSave} width={'132px'} height={'32px'}>
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};
