import { Flex, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ADDRESS_STATE_KEY, revalidateAddress } from 'apis/addressses';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { toaster } from 'components/ui/toaster';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';

type ResubmitAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ResubmitAddressModal = ({ isOpen, onClose }: ResubmitAddressModalProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleFailNotification } = useHandleNotification();
  const { isPending, mutate: doRevalidateAddress } = useMutation({
    mutationFn: () => {
      return revalidateAddress(orgId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADDRESS_STATE_KEY] });
      toaster.create({
        type: 'info',
        title: 'Cleanup Invalid Addresses in Progress.',
        description:
          'We are updating your addresses. Depending on the number of addresses, this process may take some time.',
      });
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const handleRevalidate = () => {
    onClose();
    doRevalidateAddress();
  };

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'lg'}
    >
      <DialogBackdrop />
      <DialogContent colorPalette="blue">
        <DialogHeader>
          <DialogTitle>Reprocess Addresses</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text>
            Kintsugi Intelligence will reprocess your addresses and attempt to resolve any issues and correct bad
            addresses.This can take up to 20 minutes depending on how many invalid addresses need to be processed
          </Text>
        </DialogBody>
        <DialogFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button color={'primary'} variant={'solid'} width={'90px'} onClick={handleRevalidate} loading={isPending}>
              Submit
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
